/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios"
import { useEffect, useState } from "react"
import CustomButton from "./CustomButton"
import { Setting_Password_Policy } from "lib/const"
import errorIcon from "../icon/error.svg"
import successIcon from "../icon/tick.svg"
import { iconPassword, iconShow, iconHide } from "lib/image"
import "./index.css"
import { useTranslation } from "react-i18next"
import { getI18nextLng } from "lib/cookie"

const baseDomain = process.env.REACT_APP_IDM_URL
const baseAuthDomain = process.env.REACT_APP_OAUTH_URL

const list = [
  { key: Setting_Password_Policy.allow_special_character, label: "79_496" },
  { key: Setting_Password_Policy.alphabetic_letter_required, label: "79_497" },
  { key: Setting_Password_Policy.lowercase_letter_required, label: "79_498" },
  { key: Setting_Password_Policy.uppercase_letter_required, label: "Require uppercase letter" },
  { key: Setting_Password_Policy.numbers_required, label: "79_499" },
  {
    key: Setting_Password_Policy.password_min_length,
    label: "Minimum Password Length (Characters)"
  },
  {
    key: Setting_Password_Policy.password_max_length,
    label: "Maximum Password Length (Characters)"
  },
  {
    key: Setting_Password_Policy.allowed_character_checked,
    label: "Check allowed characters (including |a-zA-z0-9| and allowed special symbol)"
  }
]

function getLabel(key, data) {
  const find = list.find((v) => v.key === key)
  if (find) {
    if (
      find.key === Setting_Password_Policy.password_min_length ||
      find.key === Setting_Password_Policy.password_max_length
    ) {
      return `${find.label}: ${data?.value} characters`
    } else {
      return find.label
    }
  }
  return ""
}

function getListSetting(keys, values) {
  var _new = values
    .map((item, index) => {
      return {
        ...item,
        key: keys[index],
        label: getLabel(keys[index], item),
        is_active: false
      }
    })
    .filter((v) => v.key !== Setting_Password_Policy.password_enforce_history)
  _new.forEach((item, index) => {
    if (
      (typeof item.value == "boolean" && !item.value) ||
      (item.key === Setting_Password_Policy.allowed_character_checked && !item.value) ||
      item.key === Setting_Password_Policy.special_symbol_required
    ) {
      _new = _new.filter((v) => v.key !== item.key)
    }
    if (item.key === Setting_Password_Policy.special_symbol_required) {
      if (!item.value) {
        _new = _new.filter((v) => v.key !== Setting_Password_Policy.allow_special_character)
      }
      _new = _new.filter((v) => v.key !== item.key)
    }
  })
  return _new
}

const ResetPassword = ({ onNext, auth, userType }) => {
  const { t } = useTranslation()
  const [newPassword, setNewPassword] = useState("")
  const [newConfirmPassword, setNewConfirmPassword] = useState("")
  const [settings, setSettings] = useState([])
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [isPass, setIsPass] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [showPass, setShowPass] = useState(false)
  const [showRePass, setShowRePass] = useState(false)

  async function fetchSetting() {
    const { access_token, organization_id } = auth
    let _rows = []

    setLoading(true)
    axios({
      url: `${baseDomain}/api/v1/org-settings/?organization_id=${organization_id}&type=1`,
      method: "get",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json"
      }
    })
      .then(({ data }) => {
        setLoading(false)
        const res = data?.data
        _rows = getListSetting(Object.keys(res), Object.values(res))
        setSettings(_rows)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const onFinish = async (e) => {
    const { access_token } = auth
    if (loading) {
      return
    }

    e.preventDefault()
    setLoading(true)
    axios({
      url:
        userType === "enduser"
          ? `${baseAuthDomain}/api/v1/reset-password/`
          : `${baseDomain}/api/v1/account/reset-password/`,
      data: {
        new_password: newPassword,
        new_password_confirm: newConfirmPassword
      },
      method: "put",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        setIsShow(false)
        setLoading(false)
        onNext()
      })
      .catch((err) => {
        setLoading(false)
        const { response } = err
        const data = response.data
        const languageCode = getI18nextLng()
        const amsErrorMsgs = JSON.parse(localStorage.getItem("errorMsg"))
        const messageAfterTranslate = getMessageAfterTranslate(data, languageCode, amsErrorMsgs)
        if (messageAfterTranslate) {
          data.error_message[0] = messageAfterTranslate
        }
        setError(data?.error_message)
      })
  }

  function getMessageAfterTranslate(data, languageCode, amsErrorMsgs) {
    const amsErrorMsg = amsErrorMsgs?.find(
      (item) => item?.language_code === languageCode && item?.error_code === data?.error_code
    )
    return amsErrorMsg?.content || undefined
  }

  const onChangePassword = (v) => {
    setNewPassword(v)
    setError("")

    if (settings.length > 0) {
      setIsShow(true)
      var _arr = [...settings]
      for (let index = 0; index < _arr.length; index++) {
        if (_arr[index].key === Setting_Password_Policy.allow_special_character) {
          _arr[index].is_active = false
          const value = _arr[index].value
          for (let j = 0; j < value.length; j++) {
            if (v.includes(value[j]) && new RegExp(_arr[index].regex).test(v)) {
              _arr[index].is_active = true
              break
            }
          }
        } else {
          if (new RegExp(_arr[index].regex).test(v)) {
            _arr[index].is_active = true
          } else {
            _arr[index].is_active = false
          }
        }
      }

      const filter = _arr.filter((v) => v.is_active)
      setIsPass(false)
      if (filter.length === _arr.length) {
        setIsPass(true)
      }

      if (newConfirmPassword !== "" && v !== newConfirmPassword) {
        setError("Re-enter password does not match to root password.")
      }
      setSettings(_arr)
    }
  }

  const onChangeConfirmPassword = (v) => {
    setNewConfirmPassword(v)
    setError("")

    if (v !== newPassword) {
      setError("Re-enter password does not match to root password.")
    }
  }

  useEffect(() => {
    if (auth) {
      fetchSetting()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  const getLabelElement = (item) => {
    let result = null
    switch (item?.key) {
      case "allow_special_character":
        result = `${t(item?.label)}: ${item.value}`
        break
      case "password_max_length":
        result = `${t("79_501")}: ${t("79_503")}`
        break
      case "password_min_length":
        result = `${t("79_505")}: ${t("79_507")}`
        break
      default:
        result = t(item?.label)
        break
    }
    return (
      <p className="text-xs" style={{ color: item.is_active ? "green" : "red" }}>
        {result}
      </p>
    )
  }

  // <p className="text-xs" style={{ color: v.is_active ? "green" : "red" }}>
  //                   {t(v?.label)}
  //                 </p>

  return (
    <div>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-[30px] text-[#171A1F] font-[700] leading-tight tracking-tight text-center">
          {t("79_472")}
        </h1>
        <div className="margin-text-top text-center text-[14px] font-[400] text-[#171A1F]">
          {t("79_473")}
        </div>
        <form className="space-y-4 md:space-y-6" onSubmit={onFinish}>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-[#000000A6]">
              {t("79_482")}
            </label>
            <input
              onChange={(e) => onChangePassword(e.target.value)}
              value={newPassword}
              type={showPass ? "text" : "password"}
              name="new_password"
              id="new_password"
              placeholder="●●●●●●"
              className="pl-9 border border-gray-300 text-gray-900 text-[16px] font-[400] rounded-[4px] tracking-widest block w-full p-2.5 placeholder:text-input-box placeholder:text-[16px] placeholder:text-[#B5B5B5E0]"
              required
            />
            <span className="relative float-left left-2 -top-[35px]">{iconPassword}</span>
            {newPassword.length > 0 && (
              <span className="field-icon cursor-pointer" onClick={() => setShowPass(!showPass)}>
                {showPass ? iconShow : iconHide}
              </span>
            )}
          </div>
          <div>
            {isShow &&
              settings.length > 0 &&
              settings.map((v) => (
                <div className="margin-text-top flex items-center" key={v?.key}>
                  {v.is_active ? (
                    <img className="mr-2" src={successIcon} width={20} height={20} alt="" />
                  ) : (
                    <img className="mr-2" src={errorIcon} width={20} height={20} alt="" />
                  )}
                  {/* <p className="text-xs" style={{ color: v.is_active ? "green" : "red" }}>
                    {t(v?.label)}
                  </p> */}
                  {getLabelElement(v)}
                </div>
              ))}
          </div>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-[#000000A6]">
              {t("79_490")}
            </label>
            <input
              onChange={(e) => onChangeConfirmPassword(e.target.value)}
              onFocus={() => {
                if (isPass) {
                  setIsShow(false)
                }
              }}
              value={newConfirmPassword}
              type={showRePass ? "text" : "password"}
              name="new_password_confirm"
              id="new_password_confirm"
              placeholder="●●●●●●"
              className="pl-9 border border-gray-300 text-gray-900 text-[16px] font-[400] rounded-[4px] tracking-widest block w-full p-2.5 placeholder:text-input-box placeholder:text-[#B5B5B5E0]"
              required
            />
            <span className="relative float-left left-2 -top-[35px]">{iconPassword}</span>
            {newConfirmPassword.length > 0 && (
              <span
                className="field-icon cursor-pointer"
                onClick={() => setShowRePass(!showRePass)}
              >
                {showRePass ? iconShow : iconHide}
              </span>
            )}
          </div>

          <div>{error !== "" && <div className="margin-text-top text-red-500">{error}</div>}</div>
          <CustomButton
            title={t("79_519")}
            loading={loading}
            disabled={(error !== "" || !isPass) && settings.length > 0 ? true : false}
          />
        </form>
      </div>
    </div>
  )
}

export default ResetPassword
