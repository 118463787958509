import axios from "axios"
import createAuthRefreshInterceptor from "axios-auth-refresh"
import { getCookieAuth } from "lib/cookie"
import { v4 as uuidv4 } from "uuid"
import { getI18nextLng } from "lib/cookie"

const globalId = uuidv4()
const refreshAuthLogic = (failedRequest) =>
  axios
    .post(`${process.env.REACT_APP_DOMAIN}auth/refresh`, {
      refresh_token: getCookieAuth()?.refresh_token
    })
    .then((tokenRefreshResponse) => {
      localStorage.setItem("accessToken", tokenRefreshResponse.data.token)

      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.token

      return Promise.resolve()
    })
    .catch(() => {
      localStorage.clear()
      window.location.href = "/login"
    })

export default function getInstanceAxios(baseAPI, configHeader) {
  const instance = axios.create({
    baseURL: baseAPI
  })

  instance.interceptors.request.use(
    function (config) {
      config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Http-global-id": globalId
      }
      if (configHeader?.projectId) config.headers["project-id"] = configHeader?.projectId
      if (getCookieAuth()?.access_token)
        config.headers["Authorization"] = `Bearer ${getCookieAuth()?.access_token}`
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  function getMessageAfterTranslate(data, languageCode, amsErrorMsgs) {
    const amsErrorMsg = amsErrorMsgs?.find(
      (item) => item?.language_code === languageCode && item?.error_code === data?.error_code
    )
    return amsErrorMsg?.content || undefined
  }

  instance.interceptors.response.use(
    function (response) {
      try {
        if (response.status >= 200 && response.status < 300) return response.data
        return Promise.reject(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async function (error) {
      if (error.response) {
        const { response } = error
        const data = response.data
        const languageCode = getI18nextLng()
        const amsErrorMsgs = JSON.parse(localStorage.getItem("errorMsg"))
        const messageAfterTranslate = getMessageAfterTranslate(data, languageCode, amsErrorMsgs)
        if (messageAfterTranslate) {
          data.error_message[0] = messageAfterTranslate
        }
        return Promise.reject(data)
      }
      return Promise.reject(error)
    }
  )

  createAuthRefreshInterceptor(instance, refreshAuthLogic)
  return instance
}
