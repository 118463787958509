import { configLocale } from "lib/const"
import Client from "../client/ClientAms"

const resource = "/api"

function getTranslate(params) {
  return Client("root_all_resources").get(
    `${resource}/sdk/translation-elements/latest-version-v3`,
    {
      params: {
        design_file_id: configLocale.designFileId,
        limit: 20000,
        ...params
      }
    }
  )
}

function getTranslateErrorMessage(params) {
  return Client("root_all_resources").get(`${resource}/sdk/system-errors`, {
    params: {
      design_file_id: configLocale.designFileId,
      limit: 10000,
      ...params
    }
  })
}

const api = {
  getTranslate,
  getTranslateErrorMessage
}

export default api
