/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef } from "react"
import Login from "./Login"
import SendEmail from "./SendEmail"
import OTPCode from "./OTPCode"
import ResetPassword from "./ResetPassword"
import PasswordSuccess from "./PasswordSuccess"
import { EMAIL_TYPE } from "lib/const"
import logoImg from "../icon/logo.svg"
import { ERROR_API_CODE } from "lib/const"

const Main = () => {
  const [step, setStep] = useState(1)
  const [firstLogin, setFirstLogin] = useState(false)
  const [isPasswordExpired, setIsPasswordExpired] = useState(false)
  const [email, setEmail] = useState("")
  const [userType, setUserType] = useState(null)
  const [type, setType] = useState(EMAIL_TYPE.Reset)
  const [auth, setAuth] = useState(null)
  const errorCode = useRef()

  function onActionFromGetError(isPasswordExpired, _errorCode) {
    if (isPasswordExpired) {
      setType(EMAIL_TYPE.Reset)
    } else {
      setType(EMAIL_TYPE.Confirm)
    }
    setIsPasswordExpired(isPasswordExpired)
    setFirstLogin(!isPasswordExpired)
    errorCode.current = _errorCode
    setStep(2)
  }

  function onForgot() {
    setType(EMAIL_TYPE.Reset)
    setFirstLogin(false)
    setStep(2)
  }

  function onConfirmOTP(auth) {
    setAuth(auth)
    if (userType === "enduser") {
      if (
        errorCode.current === ERROR_API_CODE.EndUser_Password_Expired ||
        type === EMAIL_TYPE.Reset
      ) {
        setStep(4)
      } else {
        setStep(1)
      }
    } else {
      setStep(4)
    }
  }

  function onSendEmail(email, userType) {
    setEmail(email)
    setUserType(userType)
    setStep(3)
  }

  function onBackLogin() {
    setIsPasswordExpired(false)
    setStep(1)
  }

  return (
    <section>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        {step === 1 && (
          <div>
            <img src={logoImg} alt="logo" className="logo" />
          </div>
        )}
        <div className="w-full bg-white md:mt-0 sm:max-w-md xl:p-0">
          {step === 1 && <Login onNext={onActionFromGetError} onForgot={onForgot} />}
          {step === 2 && (
            <SendEmail
              isFirstLogin={firstLogin}
              isPasswordExpired={isPasswordExpired}
              onNext={onSendEmail}
              onBack={onBackLogin}
            />
          )}
          {step === 3 && (
            <OTPCode type={type} email={email} userType={userType} onNext={onConfirmOTP} />
          )}
          {step === 4 && (
            <ResetPassword auth={auth} onNext={() => setStep(5)} userType={userType} />
          )}
          {step === 5 && <PasswordSuccess onNext={onBackLogin} />}
        </div>
      </div>
    </section>
  )
}

export default Main
