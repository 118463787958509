export const EMAIL_TYPE = {
  Confirm: 1,
  Reset: 2
}

export const ORG_TYPE = {
  USER_SERVICE: 1,
  PROVIDER_SERVICE_2B: 2,
  PROVIDER_SERVICE_2C: 3
}

export const PORTAL_TYPE = {
  DRIVER: "DRIVER",
  PASSENGER: "PASSENGER"
}

export const configLocale = {
  designFileId: process.env.REACT_APP_TRANSLATE_DESIGN_FILE,
  projectId: process.env.REACT_APP_TRANSLATE_PROJECT_ID
}

export const SERVICE_CODE = {
  API_AMS: "api-ams"
}
export const Setting_Password_Policy = {
  allow_special_character: "allow_special_character",
  alphabetic_letter_required: "alphabetic_letter_required",
  lowercase_letter_required: "lowercase_letter_required",
  uppercase_letter_required: "uppercase_letter_required",
  numbers_required: "numbers_required",
  password_min_length: "password_min_length",
  password_max_length: "password_max_length",
  allowed_character_checked: "allowed_character_checked",
  password_enforce_history: "password_enforce_history",
  special_symbol_required: "special_symbol_required"
}

export const ERROR_API_CODE = {
  Resource_Not_Found: "IDM_RNF_404",
  Unable_To_Login: "IDM_IUPWD_400",
  Resource_Deactive: "IDM_RD_423",
  User_Deactive: "IDM_UD_423",
  User_Verify: "IDM_UVY_410",
  EndUser_Verify: "IDM_EUVY_410",
  User_Not_Found: "IDM_UNF_404",
  User_Locked: "IDM_IULOCKED_400", // user is locked because of logining many times.
  EndUser_Locked: "IDM_IEULOCKED_400", // user is locked because of logining many times.
  User_Password_Expired: "IDM_PWUE_400",
  EndUser_Password_Expired: "IDM_PWEUE_400",
  Fail_Parse_Json: "IDM_FTPJB_400",
  Missing_Authorization_Token: "IDM_MAT_404",
  Fail_Validate_Authorization_Token: "IDM_FTVAT_401",
  Fail_Get_Token_Claims: "IDM_FTGTC_400",
  Permission_Not_Allow: "IDM_PNA_403",
  Item_Already_Exists: "IDM_IAE_409",
  Title_Can_Not_Blank: "IDM_TCNBB_400",
  Item_Not_Found: "IDM_INF_404",
  Record_Not_Found: "IDM_RCNF_404",
  Can_Not_Update_Finished_Item: "IDM_CNUFI_400",
  Data_Provided_Not_Correct: "IDM_TDPINC_400",
  Data_Provided_Forbidden: "IDM_TDPIF_403",
  Acccess_Token_Revoked: "IDM_ACT_401",
  Refresh_Token_Revoked: "IDM_RFT_401",
  Password_Incorrect: "IDM_PWDI_400",
  Username_Already_Exists: "IDM_UAE_409",
  Organization_Already_Exists: "IDM_ORGAE_409",
  Service_Provider_Code_Already_Exists: "IDM_SPCAE_409",
  Verification_Confirm_Code_Expired: "IDM_VUCCE_417",
  Verification_Confirm_Code_Invalid: "IDM_VUCCI_400",
  Role_Name_Duplicated: "IDM_RND_409",
  Client_Not_Found: "IDM_CLF_404",
  Invalid_Client_Secret: "IDM_ICS_400",
  ClientID_Taken: "IDM_CIDT_400",
  Authorization_Code_Not_Found: "IDM_ACNF_400",
  Authorization_Code_Expired: "IDM_ACE_417",
  Invalid_RedirectURI: "IDM_IRURL_400",
  Invalid_Scope: "IDM_IC_400",
  Refresh_Token_Not_Found: "IDM_RFTNF_404",
  Refresh_Token_Expired: "IDM_RFTE_401",
  Requested_Scope_Cannot_Be_Greater: "IDM_RSCBG_400",
  Error_When_Substring: "ErrorWhenSubstring"
}

export const PHONE_COUNTRY = {
  thaiLand: {
    code: "+66",
    length: 9
  },
  southAfrica: {
    code: "+27",
    length: 9
  },
  japan: {
    code: "+81",
    length: 10
  },
  poland: {
    code: "+590",
    length: 9
  }
}
